@use "../global/" as g;

.p-top{
  &__category{
    margin-bottom: 64px;
    &__list{
      display: flex;
      flex-wrap: wrap;
      gap: 24px 0;
      width: 100%;
      max-width: 896px;
      margin: 0 auto;
      li{
        width: 46.5%;
        border: solid 1px #E0E0E0;
        border-radius: 4px;
        background-color: g.$c_white;
        transition: 0.3s;
        @include g.mq{
          width: 22.4%;
        }
        @include g.mq_m{
          width: 17.85%;
        }
        &:not(:nth-child(2n)){
          margin-right: 7%;
          @include g.mq{
            margin-right: 0;
          }
        }
        &:not(:nth-child(4n)){
          @include g.mq{
            margin-right: 3.46%;
          }
          @include g.mq_m{
            margin-right: 0;
          }
        }
        &:not(:nth-child(5n)){
          @include g.mq_m{
            margin-right: 2.6875%;
          }
        }
        &:hover{
          border: solid 1px g.$c_green;
          background-color: g.$c_green;
          a{
            color: g.$c_white;
          }
        }
        a{
          width: 100%;
          display: inline-block;
          font-size: 18px;
          color: g.$c_black;
          font-weight: 500;
          padding: 16px 0 16px 16px;
          position: relative;
          transition: 0.3s;
          &:before{
            content: "";
            position: absolute;
            width: 15px;
            height: 8.5px;
            background-image: url(../images/common/content_sankaku.svg);
            background-size: 100% 100%;
            right: 5px;
            bottom: 5px;
          }
        }
      }
    }
  }
  &__pickup{
    padding-bottom: 80px;
    &__content{
      width: 100%;
      display: flex;
      gap: 40px 0;
      flex-direction: column;
      @include g.mq{
        gap: 16px 0;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    &__detail{
      position: relative;
      @include g.mq{
        width: 48.3%;
      }
      @include g.mq_m{
        width: 32.3%;
      }
      .image{
        margin-bottom: 25px;
      }
      .text{
        padding-bottom: 40px;
        border-bottom: solid 1px #E0E0E0;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 18px;
          height: 10px;
          background-image: url(../images/common/content_sankaku.svg);
          background-size: 100% 100%;
          bottom: 8px;
          right: 0px;
        }
        .top{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .category{
            display: inline-block;
            padding: 8px 12px 8px 24px;
            border: solid 1px #E0E0E0;
            font-size: 12px;
            color: g.$c_black;
            font-weight: 500;
            position: relative;
            margin-right: 16px;
            &:before{
              content: "";
              position: absolute;
              width: 6px;
              height: 6px;
              background-color: g.$c_green;
              left: 12px;
              border-radius: 1px;
              top: 1px;
              bottom: 0;
              margin: auto;
            }
          }
          .day{
            display: flex;
            align-items: center;
            font-size: 12px;
            color: g.$c_black;
            .date{
              &:nth-child(1){
                margin-right: 1em;
              }
            }
          }
        }
        .title{
          font-size: 18px;
          font-weight: 500;
          line-height: 1.6;
          a{
            color: g.$c_black;
            padding-bottom: .25em;
            background: linear-gradient(g.$c_black,g.$c_black) 0 100%/0 2px no-repeat;
            transition: background .4s;
            text-decoration: none;
            &:hover{
              background-size: 100% 1px;
            }
          }
        }
      }
      &:nth-child(1){
        @include g.mq{
          width: 100%;
          display: flex;
          align-items: flex-start;
        }
        .image{
          @include g.mq{
            width: 446px;
            margin-right: 24px;
          }
        }
        .text{
          flex: 1;
          .top{
            display: block;
            @include g.mq_m{
              display: flex;
            }
            .category{
              margin-bottom: 20px;
              @include g.mq_m{
                margin-bottom: 0;
                font-size: 14.4px;
              }
            }
            .day{
              @include g.mq_m{
                font-size: 14.4px;
              }
            }
          }
          .title{
            @include g.mq_m{
              font-size: 22px;
              line-height: 1.6;
            }
          }
        }
      }
      &:nth-child(2),&:nth-child(3){
        @include g.mq_m{
          margin-right: 1.55%;
        }
      }
      &:nth-child(4){
        display: none;
        @include g.mq_m{
          display: block;
        }
      }
    }
  }
}