@use "../global/" as g;

.o-mv{
  &.is-top{
    background-image: url(../images/top/mv_sp.webp);
    background-size: cover;
    background-position: bottom;
    padding-top: 100px;
    padding-bottom: 220px;
    margin-bottom: 168px;
    @include g.mq{
      background-image: url(../images/top/mv_tab.webp);
      height: 612px;
      padding-top: 112px;
      margin-bottom: 32px;
      padding-bottom: 0;
      display: flex;
      align-items: center;
    }
    @include g.mq_m{
      background-image: url(../images/top/mv_pc.webp);
      height: 709px;
      padding-bottom: 0;
    }
    @include g.mq_ll{
      background-image: url(../images/top/mv_max.webp);
    }
    .no-webp & {
      background-image: url(../images/top/mv_sp.png);
      @include g.mq{
        background-image: url(../images/top/mv_tab.png);
      }
      @include g.mq_m{
        background-image: url(../images/top/mv_pc.png);
      }
      @include g.mq_ll{
        background-image: url(../images/top/mv_max.png);
      }
    }
    .inner{
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
      padding: 0 16px;
      position: relative;
      @include g.mq{
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      @include g.mq_mm{
        padding: 0;
      }
      @include g.mq_ll{
        max-width: 1376px;
      }
      .text{
        @include g.mq{
          width: 56%;
        }
        @include g.mq_mm{
          width: 562px;
        }
        .top{
          text-align: center;
          background-color: g.$c_white;
          font-size: 18px;
          font-weight: 500;
          border-radius: 4px;
          margin-bottom: 12px;
          padding: 8px 0;
          @include g.mq{
            margin-bottom: 16px;
          }
          @include g.mq_mm{
            font-size: 20px;
          }
        }
        h1{
          font-size: 40px;
          line-height: 1.4;
          margin-bottom: 24px;
          font-weight: 500;
          color: #333;
          @include g.mq{
            margin-bottom: 32px;
          }
          @include g.mq_mm{
            font-size: 50px;
            line-height: 1.5;
          }
          span{
            color: g.$c_green;
          }
        }
        .discription{
          font-size: 16px;
          line-height: 1.8;
          color: g.$c_black;
          margin-bottom: 32px;
          @include g.mq{
            margin-bottom: 48px;
          }
          @include g.mq_mm{
            font-size: 18px;
            line-height: 1.4;
          }
        }
        .btn{
          @include g.mq{
            width: 264px;
          }
          p{
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 12px;
            span{
              position: relative;
              &:before{
                content: "";
                position: absolute;
                width: 1px;
                height: 16px;
                background-color: #333;
                top: 0;
                bottom: 0;
                margin: auto;
                right: -16px;
                transform: rotate(30deg);
              }
              &:after{
                content: "";
                position: absolute;
                width: 1px;
                height: 16px;
                background-color: #333;
                top: 0;
                bottom: 0;
                margin: auto;
                left: -16px;
                transform: rotate(-30deg);
              }
            }
          }
          a{
            background-color: g.$c_green;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 312px;
            height: 64px;
            border-radius: 70px;
            margin: 0 auto;
            font-size: 16px;
            font-weight: bold;
            color: g.$c_white;
            position: relative;
            &:hover{
              opacity: 1;
              &:after{
                right: 16px;
              }
            }
            &:before{
              content: "";
              position: absolute;
              background-image: url(../images/common/siryou.svg);
              width: 18px;
              height: 18px;
              background-size: 100% 100%;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 24px;
            }
            &:after{
              content: "";
              position: absolute;
              width: 15.5px;
              height: 8.17px;
              background-image: url(../images/common/white_yajirushi.svg);
              background-size: 100% 100%;
              right: 24px;
              top: 0;
              bottom: 0;
              margin: auto;
              transition: 0.3s;
            }
          }
        }
      }
      .image{
        position: absolute;
        bottom: -360px;
        right: 0;
        left: 0;
        margin: auto;
        width: 100%;
        max-width: 370px;
        @include g.mq{
          position: initial;
          width: 40%;
          max-width: 100%;
        }
        @include g.mq_mm{
          width: 496px;
        }
        @include g.mq_ll{
          width: 628px;
        }
      }
    }
  }
  &.is-lower{
    padding: 90px 0 24px;
    background-image: url(../images/lower/mv_sp.webp);
    background-size: cover;
    background-position: top;
    @include g.mq{
      background-image: url(../images/lower/mv_tab.webp);
    }
    @include g.mq_m{
      background-image: url(../images/lower/mv_pc.webp);
      padding: 147px 0 32px;
    }
    @include g.mq_ll{
      background-image: url(../images/lower/mv_max.webp);
    }
    .no-webp & {
      background-image: url(../images/lower/mv_sp.png);
      @include g.mq{
        background-image: url(../images/lower/mv_tab.png);
      }
      @include g.mq_m{
        background-image: url(../images/lower/mv_pc.png);
      }
      @include g.mq_ll{
        background-image: url(../images/lower/mv_max.png);
      }
    }
    .inner{
      padding: 0 16px;
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
      @include g.mq{
        padding: 0 24px;
      }
      @include g.mq_mm{
        padding: 0;
      }
      @include g.mq_ll{
        max-width: 1376px;
      }
      .sub-title{
        padding-left: 22px;
        font-size: 14px;
        margin-bottom: 16px;
        @include g.mq{
          padding-left: 25px;
          margin-bottom: 16px;
        }
      }
      .title{
        color: #333;
        font-weight: bold;
        font-size: 32px;
        padding-left: 22px;
        position: relative;
        @include g.mq{
          padding-left: 25px;
        }
        &:before{
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
          background-color: g.$c_green;
          border-radius: 2.25px;
          top: 10px;
          left: 0;
        }
      }
    }
  }
}