@use "../global/" as g;

.o-bread_list{
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0;
  margin-bottom: 48px;
  &.is-single{
    margin-bottom: 0;
  }
  li{
    font-size: 12px;
    position: relative;
    line-height: 1.2;
    color: g.$c_black;
    @include g.mq_m{
      font-size: 14px;
    }
    &:not(:last-child){
      margin-right: 32px;
      &:before{
        content: '';
        width: 8px;
        height: 8px;
        border-top: solid 1px g.$c_black;
        border-right: solid 1px g.$c_black;
        position: absolute;
        right: -16px;
        top: 4px;
        transform: rotate(45deg);
      }
    }
    a{
      color: #4D4D4D;
      position: relative;
      @include g.mq_m{
        &:hover{
          &:before{
            content: '';
            position: absolute;
            left: 0;
            bottom: -6px;
            height: 1px;
            background-color: #333;
            animation: border_anim 0.3s linear forwards;
          }
        }
      }
    }
  }
}