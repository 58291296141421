@use "../global/" as g;

.o-flex{
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  padding: 0 16px;
  @include g.mq{
    padding: 0 24px;
  }
  @include g.mq_m{
    width: 96%;
    display: flex;
    justify-content: space-between;
  }
  @include g.mq_mm{
    width: 96%;
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }
  @include g.mq_l{
    width: 100%;
  }
  @include g.mq_ll{
    max-width: 1376px;
  }
  &__main{
    margin-bottom: 48px;
    @include g.mq_m{
      margin-bottom: 0;
      padding-top: 5px;
      width: calc(100% - 340px);
    }
    .is-single & {
      position: relative;
    }
    &__content{
      margin-bottom: 64px;
      @include g.mq{
        margin-bottom: 80px;
      }
    }
  }
  &__sidebar{
    @include g.mq_m{
      width: 300px;
      padding-bottom: 0;
      padding-top: 63px;
    }
    &.is-single{
      padding-top: 45px;
    }
  }
}