@use "../global/" as g;

.p-single{
  &__image{
    margin-bottom: 40px;
    text-align: center;
    @include g.mq{
      margin-bottom: 64px;
    }
    @include g.mq_m{
      margin-bottom: 48px;
    }
    @include g.mq_ll{
      margin-bottom: 64px;
    }
  }
  &__top{
    margin-bottom: 24px;
    @include g.mq{
      margin-bottom: 32px;
    }
    @include g.mq_m{
      margin-bottom: 48px;
    }
    &__text{
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      @include g.mq_m{
        margin-bottom: 35px;
      }
      .category{
        margin-right: 16px;
        display: inline-block;
        padding: 8px 12px 8px 23px;
        border-radius: 4px;
        border: solid 1px #E0E0E0;
        background-color: g.$c_white;
        font-size: 12px;
        font-weight: 500;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          background-color: g.$c_green;
          border-radius: 1px;
          top: 0;
          bottom: 0;
          left: 11px;
          margin: auto;
          display: flex;
          align-items: center;
        }
      }
      .date{
        font-size: 12px;
        display: flex;
        &__detail{
          &:not(:last-child){
            margin-right: 1em;
          }
        }
      }
    }
    &__title{
      font-size: 18px;
      color: g.$c_black;
      line-height: 1.6;
      font-weight: 500;
      @include g.mq{
        font-size: 34px;
      }
    }
  }
  &__sns{
    margin-bottom: 56px;
    transform: 0.5s;
    z-index: 1;
    @include g.mq{
      margin-bottom: 72px;
    }
    @include g.mq_l{
      margin-bottom: 0;
      position: absolute;
      top: 0px;
      left: -80px;
    }
    &.is-fixed{
      position: fixed;
      width: 58px;
      top: 120px;
    }
    p{
      line-height: 1;
      font-size: 20px;
      color: g.$c_black;
      font-weight: bold;
      margin-bottom: 32px;
      text-align: center;
      @include g.mq_mm{
        display: none;
      }
    }
    ul{
      width: 100%;
      max-width: 246px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      @include g.mq_l{
        max-width: 58px;
        display: block;
      }
      li{
        width: 40px;
        @include g.mq_l{
          &:not(:last-child){
            margin-bottom: 24px;
          }
        }
        &:before{
          content: none !important;
        }
        &:hover{
          cursor: pointer;
        }
        a{
          &:hover{
            cursor: pointer;
            opacity: 1;
          }
        }
      }
    }
  }
  &__list{
    width: 100%;
    padding: 80px 16px 32px;
    margin: 0 auto 24px;
    background-color: #F9F9F9;
    position: relative;
    border-radius: 4px;
    @include g.mq{
      width: 100%;
      padding: 60px 32px 40px;
      margin: 0 auto 50px;
    }
    &__sankaku{
      position: absolute;
      top: 34px;
      right: 0px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10.63px 9.365px 0 9.365px;
      border-color: g.$c_green transparent transparent transparent;
      transition: 0.3s;
      @include g.mq{
        top: 44px;
      }
      &.is-close{
        transform: rotate(180deg);
      }
    }
    &__title{
      color: g.$c_black;
      font-size: 18px;
      font-weight: 500;
      position: absolute;
      display: flex;
      align-items: flex-end;
      top: 0;
      left: 0;
      right: 0;
      height: 63px;
      width: calc(100% - 32px);
      border-bottom: solid 1px #333;
      margin: auto;
      padding-bottom: 16px;
      @include g.mq{
        width: calc(100% - 64px);
        height: 73px;
      }
      .js-list_button{
        @include g.mq{
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
    &__content{
      margin-top: 8px;
      margin-bottom: 10px;
      padding-left: 8px !important;
      @include g.mq{
        margin-top: 24px;
        margin-bottom: 20px;
      }
      &-h2{
        margin-bottom: 0 !important;
      }
      &-h3{
        padding-left: 1em;
        margin-bottom: 0 !important;
        li{
          list-style-type:disc !important;
        }
        .p-single__list__item{
          border-bottom: 0px;
          @include g.mq{
            line-height: 19px;
          }
          &:not(:last-child){
            margin-bottom: 0;
          }
          a{
            @include g.mq{
              font-size: 14px;
            }
          }
        }
      }
      &-h4{
        padding-left: 1em;
        margin-bottom: 0 !important;
        .p-single__list__item{
          margin-bottom: 0;
          &:not(:last-child){
            margin-bottom: 0;
          }
        }
      }
    }
    &__item{
      &:before{
        content: none !important;
      }
      a{
        font-size: 14px;
        color: g.$c_black;
        line-height: 2;
        position: relative;
        &:hover{
          opacity: 1;
          @include g.mq(){
            cursor: pointer;
            &:before{
              content: '';
              position: absolute;
              left: 0;
              bottom: -1px;
              border-bottom: solid 1px #707070;
              animation: border_anim 0.3s linear forwards;
            }
          }
        }
      }
    }
  }
  &__content{
    margin-bottom: 20px;
    position: relative;
    background-color: g.$c_white;
    padding: 16px;
    @include g.mq{
      margin-bottom: 32px;
      padding: 40px;
    }
    @include g.mq_m{
      padding: 40px 28px;
    }
    @include g.mq_ll{
      padding: 40px 32px;
    }
    blockquote{
      color: g.$c_black;
      font-size: 15px;
      line-height: 1.8;
      background-color: #F9F9F9;
      padding: 24px;
      margin-bottom: 24px;
      @include g.mq{
        margin-bottom: 40px;
      }
      p{
        color: g.$c_black;
        font-size: 15px;
        line-height: 1.8;
        margin-bottom: 0;
        font-style: oblique;
      }
    }
    .table_wrap{
      overflow-x: auto !important;
      overflow-y: hidden !important;
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
      @include g.mq{
        margin-bottom: 32px;
      }
      table{
        border: solid 1px #EEEEEE;
        width: auto !important;
        th{
          border: solid 1px #EEEEEE;
          padding: 16px;
          font-size: 16px;
          line-height: 1.5;
          color: g.$c_black;
          width: auto !important;
          background-color: #EEEEEE;
        }
        td{
          border: solid 1px #EEEEEE;
          padding: 16px;
          font-size: 16px;
          width: auto !important;
        }
      }
    }
    caption{
      margin-bottom: 24px;
    }
    h2,h3,h4,h5{
      position: relative;
      font-weight: bold;
    }
    h2{
      font-size: 28px;
      line-height: 1.8;
      color: g.$c_black;
      margin-bottom: 32px;
      padding-bottom: 8px;
      border-bottom: solid 1px #D7D7D7;
      position: relative;
      padding-left: 16px;
      &:before{
        content: "";
        position: absolute;
        width: 8px;
        height: 20px;
        background-image: url(../images/single/title_single.svg);
        top: 16px;
        left: 0;
        background-size: 100% 100%;
      }

    }
    h3{
      color: g.$c_black;
      font-size: 22px;
      line-height: 1.8;
      margin-bottom: 32px;
      padding-left: 11px;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: g.$c_green;
      }
    }
    h4{
      font-size: 22px;
      line-height: 1.8;
      color: g.$c_black;
      margin-bottom: 32px;
      padding-bottom: 8px;
      border-bottom: solid 1px #D7D7D7;
      position: relative;
    }
    h5{
      color: g.$c_black;
      font-size: 18px;
      line-height: 1.8;
      margin-bottom: 32px;
    }
    p{
      font-size: 16px;
      margin-bottom: 32px;
      color: g.$c_black;
      line-height: 1.8;
      mark{
        font-style: inherit;
        color: g.$c_black;
      }
      &.is-mb_js{
        margin-bottom: 64px;
      }
    }
    table{
      margin-bottom: 24px;
      width: 100%;
      border: solid 1px #EEEEEE;
      th{
        border: solid 1px #EEEEEE;
        background-color: #EEEEEE;
        padding: 16px;
        font-size: 16px;
        line-height: 1.5;
        color: g.$c_black;
      }
      td{
        border: solid 1px g.$c_black;
        padding: 16px;
        font-size: 16px;
        line-height: 1.5;
      }
      @include g.mq{
        margin-bottom: 40px;
      }
    }
    ol{
      padding-left: 1em;
      margin-bottom: 24px;
      @include g.mq{
        margin-bottom: 40px;
      }
      li{
        font-size: 16px;
        line-height: 1.8;
        list-style-type: decimal;
        color: g.$c_black;
      }
    }
    ul{
      padding-left: 1em;
      margin-bottom: 24px;
      @include g.mq{
        margin-bottom: 40px;
      }
      li{
        font-size: 16px;
        line-height: 1.8;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 1.2px;
          top: 12px;
          left: calc(2px - 1em);
          background-color: g.$c_green;
        }
      }
    }
  }
  &__quote{
    border: solid 1px g.$c_l_blue;
    background-color: rgba(223,237,248,0.67);
    margin-bottom: 20px;
    padding: 12px 8px 24px;
    @include g.mq{
      margin-bottom: 60px;
      padding: 16px 12px 40px;
    }
    &__title{
      font-size: 10px;
      color: #707070;
      margin-bottom: 14px;
      padding-left: 35px;
      @include g.mq{
        font-size: 12px;
      }
      @include g.mq_m{
        font-size: 16px;
        padding-left: 70px;
      }
    }
    &__content{
      display: flex;
      align-items: center;
      &-image{
        width: 20px;
        margin-right: 15px;
        @include g.mq_m{
          width: 40px;
          margin-right: 30px;
        }
      }
      &-text{
        flex: 1;
        color: #333;
        line-height: 1.5;
        font-size: 10px;
        @include g.mq{
          font-size: 12px;
        }
        @include g.mq_m{
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }
  &__supervision{
    margin-bottom: 56px;
    background-color: #F9F9F9;
    padding: 64px 24px 40px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    @include g.mq{
      margin-bottom: 72px;
      padding: 64px 45px 40px;
    }
    &__title{
      background-color: #343434;
      width: 140px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      left: 0;
      border-radius: 0 0 4px 0;
      font-size: 14px;
      color: g.$c_white;
      font-weight: bold;
    }
    &__content{
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &-image{
        width: 25%;
        max-width: 65px;
        margin-right: 24px;
      }
      &-text{
        flex: 1;
        .organization{
          font-size: 12px;
          color: g.$c_black;
          line-height: 1;
          margin-bottom: 16px;
        }
        .name{
          font-size: 20px;
          color: g.$c_black;
          font-weight: bold;
        }
      }
    }
    .description{
      color: g.$c_black;
      font-size: 16px;
      line-height: 1.8;
      margin-bottom: 16px;
      @include g.mq{
        margin-bottom: 50px;
      }
    }
    .btn{
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      @include g.mq{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        max-width: 654px;
      }
      .absolute_text{
        font-size: 13px;
        color: g.$c_black;
        text-align: center;
        margin-bottom: 8px;
        font-weight: 500;
        @include g.mq{
          position: absolute;
          width: 100%;
          max-width: 300px;
          left: 0;
          top: -20px;
        }
        @include g.mq_m{
          max-width: 270px;
        }
        @include g.mq_mm{
          max-width: 327px;
        }
      }
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 80px;
        &.is-red{
          margin: 0 auto 16px;
          background-color: #EE3E3E;
          border: solid 1px #EE3E3E;
          color: g.$c_white;
          position: relative;
          @include g.mq{
            width: 300px;
            margin: 0;
          }
          @include g.mq_m{
            width: 270px;
          }
          @include g.mq_mm{
            width: 327px;
          }
        }
        &.is-white{
          background-color: g.$c_white;
          border: solid 1px g.$c_black;
          color: g.$c_black;
          @include g.mq{
            width: 300px;
          }
          @include g.mq_m{
            width: 270px;
          }
          @include g.mq_mm{
            width: 312px;
          }
        }
        p:nth-child(1){
          display: flex;
          align-items: center;
          font-weight: bold;
          margin-bottom: 8px;
          span:nth-child(1){
            margin-right: 8px;
            display: inline-block;
          }
          font-size: 16px;
        }
        p:nth-child(2){
          font-size: 12px;
        }
      }
    }
  }
  &__article{
    margin-top: 56px;
    @include g.mq{
      display: flex;
      justify-content: space-between;
      margin-top: 72px;
    }
    &__detail{
      width: 100%;
      max-width: 332px;
      display: flex;
      &:not(:last-child){
        margin-bottom: 32px;
        @include g.mq{
          margin-bottom: 0;
        }
      }
      .image{
        width: 112px;
        margin-right: 16px;
      }
      .text{
        flex: 1;
        p:nth-child(1){
          color: g.$c_black;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.8;
          a{
            color: g.$c_black;
          }
        }
        p:nth-child(2){
          font-size: 12px;
          color: g.$c_black;
          line-height: 1.7;
          font-weight: 400;
          a{
            color: g.$c_black;
          }
        }
      }
    }
  }
  &__link{
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    background-color: g.$c_orange;
    border: solid 1px g.$c_orange;
    color: g.$c_white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 26px;
    font-size: 16px;
    transition: 0.5s;
    @include g.mq{
      max-width: 300px;
      height: 52px;
      font-size: 20px;
    }
    &:hover{
      background-color: g.$c_white;
      color: g.$c_orange;
      opacity: 1;
    }
  }
  &__other{
    background-color: #F9F9F9;
    max-width: 706px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 16px 8px;
    @include g.mq{
      padding: 16px;
    }
    &__title{
      font-size: 16px;
      margin-bottom: 16px !important;
      font-weight: bold;
      color: g.$c_green !important;
      span{
        display: inline-block;
        padding: 0 16px;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 8px;
          height: 20px;
          background-image: url(../images/single/title_single.svg);
          top: 6px;
          left: 0;
          background-size: 100% 100%;
        }
        &:after{
          content: "";
          position: absolute;
          width: 8px;
          height: 20px;
          background-image: url(../images/single/title_single.svg);
          top: 6px;
          right: 0;
          background-size: 100% 100%;
        }
      }
    }
    &__detail{
      display: flex;
      align-items: center;
      &-image{
        width: 88px;
        margin-right: 13px;
        @include g.mq{
          width: 169px;
          margin-right: 16px;
        }
      }
      &-text{
        flex: 1;
        p:nth-child(1){
          margin-bottom: 8px;
          span{
            padding: 4px 12px 4px 23px;
            display: inline-block;
            color: g.$c_black;
            border-radius: 4px;
            background-color: g.$c_white;
            border: solid 1px #E0E0E0;
            font-size: 12px;
            font-weight: 500;
            position: relative;
            &:before{
              content: "";
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 1.2px;
              background-color: g.$c_green;
              top: 0;
              left: 11px;
              bottom: 0;
              margin: auto;
              display: flex;
              align-items: center;
            }
          }
        }
        p:nth-child(2){
          color: g.$c_black;
          font-size: 16px;
          line-height: 1.4;
          font-weight: bold;
          a{
            color: g.$c_black;
            padding-bottom: .2em;
            background: linear-gradient(g.$c_black,g.$c_black) 0 100%/0 2px no-repeat;
            transition: background .4s;
            text-decoration: none;
            &:hover{
              background-size: 100% 1px;
            }
          }
        }
      }
    }
  }
}