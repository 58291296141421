@use "../global/" as g;

.l-header{
  width: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 75px;
  background-color: transparent;
  transition: 0.4s;
  @include g.mq_m{
    height: 112px;
  }
  &.is-back{
    background-color: g.$c_white;
    box-shadow: 0 3px 4px rgb(0 0 0 /0.07);
  }
  &.is-open{
    height: 100vh;
    height: -webkit-fill-available;
    .innerBox{
      align-items: flex-start;
    }
  }
  &__pc{
    display: none;
    @include g.mq_m{
      display: flex;
      align-items: center;
      position: absolute;
      right: 24px;
      top: 0;
      bottom: 0;
    }
    &__btn{
      width: 160px;
      height: 48px;
      display: flex;
      align-items: center;
      background-color: g.$c_green;
      color: g.$c_white;
      border: solid 1px g.$c_green;
      border-radius: 60px;
      font-size: 14px;
      font-weight: 500;
      justify-content: center;
      transition: 0.3s;
      @include g.mq_mm{
        width: 218px;
      }
      &:hover{
        background-color: g.$c_white;
        color: g.$c_green;
        p:nth-child(1){
          svg{
            path{
              fill: g.$c_green;
            }
          }
        }
      }
      p:nth-child(1){
        display: inline-block;
        margin-right: 12px;
      }
    }
    &__search{
      border-radius: 60px;
      position: relative;
      margin-right: 16px;
      width: 192px;
      height: 48px;
      @include g.mq_mm{
        width: 224px;
      }
      input[type="image"]{
        border: 0px;
        background-color: transparent;
        width: 48px;
        height: 48px;
        position: absolute;
        top: 0;
        right: 0;;
      }
      input[type="text"]{
        width: 100%;
        height:48px;
        background-color: g.$c_white;
        border: 0px;
        font-size: 16px;
        padding: 0 64px 0 16px;
        border: solid 1px g.$c_black;
        border-radius: 60px;
      }
    }
  }
  &__content{
    height: 100%;
    margin: 0 auto;
    position: relative;
    .is-open & {
      background-color: g.$c_white;
    }
    @include g.mq_m{
      justify-content: space-between;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 24px 0 32px;
    }
    @include g.mq_mm{
      padding: 0;
    }
  }
  &__logo{
    width: 160px;
    padding: 16px 0 16px 16px;
    @include g.mq_m{
      width: 200px;
      position: absolute;
      top: 32px;
      padding: 0;
      left: 32px;
    }
    a{
      &:hover{
        opacity: 1;
        cursor: pointer;
      }
    }
  }
  &__list{
    display: none;
    height: calc(100% - 75px);
    background-color: g.$c_white;
    padding: 22px 16px;
    overflow-y: auto;
    .is-open & {
      display: block;
    }
    @include g.mq_m{
      display: flex;
      align-items: center;
      min-height: auto;
      height: 100%;
      padding: 0;
      padding-left: 240px;
      overflow-y: visible;
      background-color: transparent;
    }
    @include g.mq_mm{
      padding-left: 264px;
    }
    .parent{
      margin-bottom: 40px;
      @include g.mq_m{
        margin-bottom: 0;
        display: flex;
      }
      li{
        position: relative;
        &.is-open{
          .child{
            visibility: visible;
            opacity: 1;
          }
        }
        &.is-parent{
          @include g.mq_m{
            &:after{
              content: "";
              position: absolute;
              width: 7px;
              height: 3.97px;
              background-image: url(../images/common/sankaku.svg);
              background-size: 100% 100%;
              top: 4px;
              bottom: 0;
              margin: auto;
              right: 20px;
            }
          }
        }
        a{
          font-size: 18px;
          font-weight: bold;
          color: g.$c_black;
          @include g.mq_m{
            font-size: 14px;
            display: inline-block;
            margin-right: 32px;
            position: relative;
          }
          &:hover{
            opacity: 1;
            cursor: pointer;
            @include g.mq_m(){
              cursor: pointer;
              &:before{
                content: '';
                position: absolute;
                left: 0;
                bottom: -5px;
                border-bottom: solid 1px g.$c_green;
                animation: border_anim 0.3s linear forwards;
              }
            }
          }
        }
        &:not(:last-child){
          margin-bottom: 32px;
          @include g.mq_m{
            margin-bottom: 0;
          }
        }
        .child{
          margin-top: 16px;
          display: flex;
          flex-wrap: wrap;
          transition: 0.3s;
          @include g.mq_m{
            visibility: hidden;
            gap: 10px 0;
            opacity: 0;
            margin-top: 0;
            position: absolute;
            top: 40px;
            left: 0;
            width: 600px;
            background-color: g.$c_white;
            box-shadow: 0 3px 6px rgb(0 0 0 /0.25);
            padding: 16px;
          }
          li{
            width: 30%;
            margin-bottom: 10px;
            @include g.mq_m{
              width: 19%;
              margin-bottom: 0;
            }
            &:not(:nth-child(3n)){
              margin-right: 5%;
              @include g.mq_m{
                margin-right: 0;
              }
            }
            &:not(:nth-child(5n)){
              @include g.mq_m{
                margin-right: 1%;
              }
            }
            a{
              color: g.$c_black;
              font-size: 14px;
              font-weight: 500;
              font-family: 'Noto Sans JP', sans-serif;
              padding: 8px 0 8px 20px;
              border: solid 1px #E0E0E0;
              display: block;
              border-radius: 4px;
              position: relative;
              @include g.mq_m{
                margin-right: 0;
                transition: 0.3s;
                text-align: center;
                padding: 8px 0;
              }
              &:hover{
                color: g.$c_white;
                border: solid 1px g.$c_green;
                background-color: g.$c_green;
              }
              &:before{
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                background-color: g.$c_green;
                border-radius: 1px;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 10px;
                @include g.mq_m{
                  content: none;
                }
              }
            }
          }
        }
      }
    }
    &__cta{
      background-color: g.$c_yellow;
      border-radius: 4px;
      width: 100%;
      max-width: 312px;
      margin: 0 auto 24px;
      padding: 16px 24px;
      @include g.mq_m{
        display: none;
      }
      &-detail{
        margin-bottom: 16px;
        p{
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 10px;
        }
        a{
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          height: 56px;
          width: 100%;
          border-radius: 60px;
          color: g.$c_white;
          font-size: 14px;
          font-weight: bold;
          &:before{
            content: "";
            position: absolute;
            width: 15.5px;
            height: 8.17px;
            background-image: url(../images/common/white_yajirushi.svg);
            background-size: 100% 100%;
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &.is-green{
            background-color: g.$c_green;
          }
          &.is-dark{
            background-color: g.$c_dark;
          }
          span:nth-child(1){
            position: absolute;
            top: 0;
            bottom:0;
            margin: auto;
            display: flex;
            align-items: center;
            left: 24px;
          }
        }
      }
      &-tel{
        text-align: center;
      }
    }
    &__btn{
      width: 100%;
      max-width: 247px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      height: 44px;
      background-color: g.$c_yellow;
      color: #333;
      font-size: 14px;
      font-weight: bold;
      @include g.mq_m{
        display: none;
      }
      span:nth-child(1){
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
  &__ham{
    width: 48px;
    height: 48px;
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: g.$c_dark;
    border-radius: 50%;
    z-index: 3;
    @include g.mq_m{
      display: none;
    }
    .is-open & {
      background-color: transparent;
      span{
        background-color: g.$c_dark;
      }
    }
    span{
      width: 16px;
      height: 2px;
      background-color: g.$c_white;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      transition-duration:0.8s;
      &:nth-child(1){
        top: 18px;
      }
      &:nth-child(2){
        top: 23px;
      }
      &:nth-child(3){
        top: 28px;
      }
    }
  }
}



.active_line1{
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-duration:1s;
  top: 22px !important;
  border-radius: 3px;
}

.active_line2{
  opacity: 0;
}

.active_line3{
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 22px !important;
  right: -1px;
  border-radius: 3px;
}