@use "../global/" as g;

.o-btn {
  &.is-fixed{
    background-color: transparent;
    width: 100%;
    height: 72px;
    position: fixed;
    bottom: -72px;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    &.UpMove{
      animation: UpAnime 0.5s forwards;
    }
    &.DownMove{
      animation: DownAnime 0.5s forwards;
    }
    @include g.mq{
      display: none !important;
    }
    a{
      width:50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: g.$c_white;
      &:nth-child(1){
        background-color: g.$c_green;
      }
      &:nth-child(2){
        background-color: g.$c_dark;
      }
      p:nth-child(1){
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
  &.is-blog{
    width: 100%;
    max-width: 224px;
    margin: 0 auto;
    background-color: g.$c_green;
    border: solid 1px g.$c_green;
    color: g.$c_white;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.1));
    border-radius: 68px;
    font-size: 16px;
    font-weight: bold;
    transition: 0.3s;
    padding: 24px 0;
    line-height: 1;
    overflow: hidden;
    @include g.mq{
      max-width: 288px;
    }
    &:hover{
      background-color: g.$c_white;
      color: g.$c_green;
    }
  }
}
