@use "../global/" as g;

.o-blog{
  margin-bottom: 32px;
  @include g.mq{
    display: flex;
    flex-wrap: wrap;
  }
  &__detail{
    position: relative;
    margin-bottom: 40px;
    @include g.mq{
      width: 48.3%;
      margin-bottom: 0;
    }
    .image{
      margin-bottom: 25px;
    }
    .text{
      padding-bottom: 40px;
      border-bottom: solid 1px #E0E0E0;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        width: 18px;
        height: 10px;
        background-image: url(../images/common/content_sankaku.svg);
        background-size: 100% 100%;
        bottom: 8px;
        right: 0px;
      }
      .top{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .category{
          display: inline-block;
          padding: 8px 12px 8px 24px;
          border: solid 1px #E0E0E0;
          font-size: 12px;
          color: g.$c_black;
          font-weight: 500;
          position: relative;
          margin-right: 16px;
          background-color: g.$c_white;
          &:before{
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: g.$c_green;
            left: 12px;
            border-radius: 1px;
            top: 1px;
            bottom: 0;
            margin: auto;
          }
        }
        .day{
          display: flex;
          align-items: center;
          font-size: 12px;
          color: g.$c_black;
          .date{
            &:nth-child(1){
              margin-right: 1em;
            }
          }
        }
      }
      .title{
        font-size: 18px;
        font-weight: 500;
        line-height: 1.6;
        a{
          color: g.$c_black;
          padding-bottom: .25em;
          background: linear-gradient(g.$c_black,g.$c_black) 0 100%/0 2px no-repeat;
          transition: background .4s;
          text-decoration: none;
          &:hover{
            background-size: 100% 1px;
          }
        }
      }
    }
    &:nth-child(odd){
      @include g.mq{
        margin-right: 3.4%;
      }
    }
    &:not(:nth-last-child(-n+2)){
      @include g.mq{
        margin-bottom: 48px;
      }
    }
  }
}