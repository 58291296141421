@use "../global/" as g;

.l-footer{
  width: 100%;
  background-color: g.$c_white;
  position: relative;
  padding: 32px 16px 112px 16px;
  @include g.mq{
    margin-bottom: 0;
    padding: 32px 0;
  }
  @include g.mq_m{
    height: 96px;;
    padding: 0;
  }
  &__pagetop{
    display: none;
  }
  &__inner{
    width: 100%;
    max-width: 1132px;
    margin: 0 auto;
    position: relative;
    @include g.mq_m{
      display: flex;
      align-items: center;
      height: 100%;;
      padding: 0 16px;
    }
    @include g.mq_ll{
      max-width: 1376px;
    }
  }
  &__logo{
    width: 100%;
    max-width: 200px;
    margin: 0 auto 32px;
    @include g.mq_m{
      margin: 0;
    }
  }
  &__copyright{
    text-align: center;
    font-size: 14px;
    color: g.$c_black;
    @include g.mq_m{
      margin: 0 0 0 auto;
    }
  }
  &__list{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 32px;
    @include g.mq_m{
      margin: 0 0 0 40px;
      width: auto;
    }
    li{
      &:nth-child(2){
        margin: 0 16px;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: g.$c_black;
          left: -8px;
          top: 3px;
        }
        &:after{
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: g.$c_black;
          right: -8px;
          top: 3px;
        }
      }
      a{
        color: g.$c_black;
        font-size: 11px;
        font-weight: 300;
        position: relative;
        @include g.mq{
          font-size: 14px;
        }
        &:hover{
          opacity: 1;
          cursor: pointer;
          @include g.mq_m(){
            cursor: pointer;
            &:before{
              content: '';
              position: absolute;
              left: 0;
              bottom: -5px;
              border-bottom: solid 1px g.$c_black;
              animation: border_anim 0.3s linear forwards;
            }
          }
        }
      }
    }
  }
  &__pagetop{
    display: none;
    position: fixed;
    bottom: 120px;
    right: 2%;
    width: 48px;
    height: 48px;
    background-color: g.$c_dark;
    border-radius: 50%;
    box-shadow: 0 4px 2px rgb(0 0 0 /0.2);
    z-index: 9999;
    &:hover{
      opacity: 1;
    }
    &:before{
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7.5px 8.5px 7.5px;
      border-color: transparent transparent g.$c_white transparent;
      border-radius: 3px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}