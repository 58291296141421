@use "../global/" as g;

.p-download{
  padding: 64px 0 80px;
  background-color: g.$c_yellow;
  @include g.mq{
    padding: 80px 0;
  }
  &__mb{
    margin-bottom: 48px;
    @include g.mq{
      margin-bottom: 80px;
    }
  }
  &__content{
    margin-bottom: 48px;
    @include g.mq{
      margin-bottom: 80px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 32px 0;
    }
    @include g.mq_m{
      justify-content: flex-start;
    }
    &__detail{
      background-color: g.$c_white;
      border-radius: 4px;
      overflow: hidden;
      padding-bottom: 24px;
      @include g.mq{
        width: 47.6%;
        display: flex;
        flex-direction: column;
      }
      @include g.mq_m{
        width: 31.2%
      }
      &:not(:nth-child(3n)){
        @include g.mq_m{
          margin-right: 3.2%;
        }
      }
      .image{
        margin-bottom: 32px;
      }
      .title{
        padding: 0 18px;
        margin-bottom: 32px;
        a{
          color: g.$c_black;
          font-size: 18px;
          line-height: 1.4;
          padding-bottom: .2em;
          background: linear-gradient(g.$c_black,g.$c_black) 0 100%/0 2px no-repeat;
          transition: background .4s;
          text-decoration: none;
          &:hover{
            background-size: 100% 1px;
          }
        }
      }
      .btn{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        max-width: 264px;
        height: 56px;
        border-radius: 60px;
        background-color: g.$c_green;
        font-size: 14px;
        font-weight: bold;
        color: g.$c_white;
        margin: 0 auto;
        border: solid 1px g.$c_green;
        transition: 0.3s;
        @include g.mq{
          margin: auto auto 0;
        }
        &:hover{
          background-color: g.$c_white;
          color: g.$c_green;
          span:nth-child(1){
            svg{
              path{
                fill: g.$c_green;
              }
              stroke{
                fill: g.$c_green;
              }
            }
          }
        }
        span:nth-child(1){
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 18px;
          display: flex;
          align-items: center;
        }
        span:nth-child(3){
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 18px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  &__flex{
    @include g.mq_m{
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
    }
  }
  &__left{
    margin-bottom: 80px;
    @include g.mq_m{
      width: 39.27%;
      max-width: 430px;
      margin-bottom: 0;
    }
    &__image{
      margin-bottom: 40px;
      @include g.mq{
        margin-bottom: 32px;
      }
      &-main{
        margin-bottom: 40px;
        text-align: center;
        @include g.mq{
          margin-bottom: 32px;
        }
        img{
          transition: 0.3s;
        }
      }
      &-thumbnail{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include g.mq{
          justify-content: flex-start;
        }
        li{
          width: 47.3%;
          @include g.mq{
            width: 31.4%;
          }
          &:hover{
            cursor: pointer;
          }
          &:not(:nth-child(3n)){
            @include g.mq{
              margin-right: 2.9%;
            }
          }
        }
      }
    }
    &__list{
      background-color: #F9F9F9;
      padding: 20px 28px;
      margin-bottom: 32px;
      p{
        font-size: 18px;
        color: g.$c_black;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 24px;
      }
      ul{
        li{
          line-height: 1.8;
          padding-left: 24px;
          color: #333;
          font-size: 14px;
          position: relative;
          &:before{
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            background-image: url(../images/common/sidebar_check.svg);
            background-size: 100% 100%;
            left: 0;
            top: 6px;
          }
          &:not(:last-child){
            margin-bottom: 8px;
          }
        }
      }
    }
    &__discription{
      color: g.$c_black;
      font-size: 16px;
      line-height: 1.8;
    }
  }
  &__right{
    @include g.mq_m{
      flex: 1;
      margin-left: 40px;
    }
    &__text{
      margin-bottom: 16px;
      color: g.$c_black;
      font-size: 16px;
      line-height: 1.8;
    }
  }
}