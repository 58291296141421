@use "../global/" as g;

.o-pagenation{
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  .wp-pagenavi{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 44px;
      width: 48px;
      height: 48px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: g.$c_black;
      margin: 0 4px;
      overflow: hidden;
      position: relative;
      transition: 0.1s;
      border: 0px;
      border: solid 1px #333;
      background-color: transparent;
      &:hover{
        background-color: g.$c_white;
      }
      &.previouspostslink{
        text-indent: 1000%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        &:before{
          content: "";
          position: absolute;
          width: 8.17px;
          height: 7px;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0px;
          margin: auto;
          background: url(../images/common/pagenation.svg);
          background-size: 100% 100%;
          transform: rotate(180deg);
          transition: 0.3s;
        }
      }
      &.nextpostslink{
        text-indent: 1000%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        &:before{
          content: "";
          position: absolute;
          width: 8.17px;
          height: 7px;
          top: 0;
          bottom: 0;
          right: 0px;
          left: 0;
          margin: auto;
          background: url(../images/common/pagenation.svg);
          background-size: 100% 100%;
          transition: 0.3s;
        }
        &:hover{
          &:before{
            right: -4px;
          }
        }
      }
    }
    .current{
      display: block;
      width: 48px;
      height: 48px;
      line-height: 45px;
      text-align: center;
      margin: 0 4px;
      background-color: #333;
      border: solid 1px #333;
      border-radius: 4px;
      color: g.$c_white;
      font-size: 16px;
      font-weight: bold;
    }
  }
}