/*
 * 色の設定
 */

$c_white: #ffffff;
$c_yellow: #FAF7EF;
$c_black: #000;
$c_orange: #FFA721;
$c_l_blue: #004E98;
$c_green: #63B200;
$c_dark: #A38D48;


//ブレイクポイント
$breakpoint: 768px;
$breakpoint_m: 1024px;
$breakpoint_mm: 1180px;
$breakpoint_l: 1280px;
$breakpoint_design: 1440px;
$breakpoint_ll: 1728px;
$breakpoint_max: 1920px;