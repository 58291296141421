@use "../global/" as g;

.o-contact_btn{
  width: 100%;
  max-width: 564px;
  margin: 0 auto 32px;
  @include g.mq{
    display: flex;
    justify-content: space-between;
  }
  &__detail{
    width: 100%;
    max-width: 264px;
    margin: 0 auto;
    @include g.mq{
      margin: 0;
    }
    p{
      text-align: center;
      margin-bottom: 4px !important;
      font-size: 14px;
      font-weight: bold;
      span{
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 1px;
          height: 16px;
          background-color: #333;
          top: 0;
          bottom: 0;
          margin: auto;
          right: -16px;
          transform: rotate(30deg);
        }
        &:after{
          content: "";
          position: absolute;
          width: 1px;
          height: 16px;
          background-color: #333;
          top: 0;
          bottom: 0;
          margin: auto;
          left: -16px;
          transform: rotate(-30deg);
        }
      }
    }
    a{
      position: relative;
      display: flex;
      border-radius: 60px;
      height: 56px;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      color: g.$c_white;
      font-weight: bold;
      transition: 0.3s;
      span:nth-child(1){
        left: 24px;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        align-items: center;
        display: flex;
      }
      span:nth-child(3){
        right: 24px;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        align-items: center;
        display: flex;
      }
    }
    &:nth-child(1){
      margin: 0 auto 16px;
      @include g.mq{
        margin: 0;
      }
      a{
        background-color: g.$c_green;
        border: solid 1px g.$c_green;
        &:hover{
          color: g.$c_green;
          opacity: 1;
          background-color: g.$c_white;
          svg{
            path{
              fill: g.$c_green;
            }
          }
        }
      }
    }
    &:nth-child(2){
      p{
        display: none;
        @include g.mq{
          display: block;
        }
      }
      a{
        background-color: g.$c_dark;
        border: solid 1px g.$c_dark;
        &:hover{
          color: g.$c_dark;
          opacity: 1;
          background-color: g.$c_white;
          svg{
            path{
              fill: g.$c_dark;
            }
          }
        }
      }
    }
  }
}